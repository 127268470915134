





























import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import AccessFilter from '@bertazzoni/back/components/AccessFilter.vue'
import RichText from '@bertazzoni/back/components/RichText.vue'
import CommunicationsService from '@bertazzoni/common/src/services/CommunicationsService'
import FormError from '@bertazzoni/common/src/components/FormError.vue'

@Component({
  components: {
    Loader,
    FormError,
    Icons,
    AccessFilter,
    RichText
  }
})
export default class CustomCom extends Vue {
  private recipients: string[]
  private pageLoaded = false
  private submitLoading = false
  private error = { description: false, recipients: false }

  editAccessList(newAccessList: string[]): void {
    this.recipients = newAccessList
    this.checkRecipientsError()
  }
  async checkErrors(): Promise<void> {
    this.checkRecipientsError()
    if (!this.error.recipients) {
      this.exportRecipients()
    }
  }
  checkRecipientsError(): void {
    if (this.recipients && this.recipients.length > 0) {
      this.error.recipients = false
    } else {
      this.error.recipients = true
    }
  }
  async exportRecipients() {
    this.changeLoading()
    const csv = await CommunicationsService.ExportEmails(this.recipients)
    //Download csv
    const anchor = document.createElement('a')
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    anchor.target = '_blank'
    anchor.download = 'exportedRecipients.csv'
    anchor.click()

    this.changeLoading()
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
